export default ({ fetchMock, delay, mock, toSuccess, toError }) => {
  return {
    '/api/user/menu': options => toSuccess([
      {
        name: '控制面板',
        icon: 'DashboardOutlined',
        path: '/dashboard',
      },
      {
        name: '矿工管理',
        icon: 'RadarChartOutlined',
        path: '/miners',
        children: [
          {
            name: '矿工列表',
            path: '/miners/list',
          },
          {
            name: '机房列表',
            path: '/engineRooms/list',
          },
          {
            name: '收益列表',
            path: '/miningEarnings/list',
          },
        ],
      },
      {
        name: '运营管理',
        icon: 'RadarChartOutlined',
        path: '/yunying',
        children: [
          {
            name: '商户列表',
            path: '/merchants/list/:clients?',
          },
          {
            name: '扩容请求',
            path: '/powers/list',
          },
          {
            name: '客户列表',
            path: '/members/list',
          },
          {
            name: '算力订单',
            path: '/orders/list',
          },
          {
            name: '提现请求',
            path: '/withdraws/list',
          },
          {
            name: '钱包流水',
            path: '/walletChainRequestLogs/list',
          },
        ],
      },
      {
        name: '收益管理',
        icon: 'RadarChartOutlined',
        path: '/distributes',
        children: [
          {
            name: '分FIL列表',
            path: '/distributes/list',
          },
        ],
      },
      {
        name: '质押管理',
        icon: 'RadarChartOutlined',
        path: '/pledges',
        children: [
          {
            name: '质押列表',
            path: '/pledges/list',
          },
          {
            name: '还款日志',
            path: '/revertLogs/list',
          },
        ],
      },
      {
        name: '系统管理',
        icon: 'RadarChartOutlined',
        path: '/system',
        children: [
          {
            name: '系统配置',
            path: '/system/setting',
          },
        ],
      },
    ], 400)
  }
}