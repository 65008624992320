import { createRoutes } from '@/utils/core';
import BasicLayout from '@/custom/layouts/BasicLayout';
import UserLayout from '@/custom/layouts/UserLayout';
import Page403 from './Pages/403';
import NotFound from './Pages/404';
import Page500 from './Pages/500';
import Dashboard from './Dashboard';
import Miners from './Miners';
import Pledges from './Pledges';
import System from './System';
import EngineRooms from './EngineRooms';
import RevertLogs from './RevertLogs';
import WalletChainRequestLogs from './WalletChainRequestLogs';
import MiningEarnings from './MiningEarnings';
import MerchantDistributes from './MerchantDistributes';
import Merchants from './Merchants';
import MerchantClients from './Merchants/routers/Clients';
import MerchantMiners from './Merchants/routers/Miners';
import MemberInitialPledges from './Merchants/routers/InitialPledges';
import MemberProducts from './Merchants/routers/Products';
import MerchantPowers from './MerchantPowers';
import Login from './Login';
import ChangePassword from './ChangePassword';

/**
 * 主路由配置
 * 
 * path 路由地址
 * component 组件
 * indexRoute 默认显示路由
 * childRoutes 所有子路由
 * NotFound 路由要放到最下面，当所有路由当没匹配到时会进入这个页面
 */
const routesConfig = app => [
  {
    path: '/sign',
    title: '登录',
    indexRoute: '/sign/login',
    component: UserLayout,
    childRoutes: [
      Login(app),
      NotFound()
    ]
  },
  {
    path: '/',
    title: '系统中心',
    component: BasicLayout,
    indexRoute: '/dashboard',
    childRoutes: [
      Dashboard(app),
      Miners(app),
      Pledges(app),
      System(app),
      EngineRooms(app),
      RevertLogs(app),
      WalletChainRequestLogs(app),
      MiningEarnings(app),
      MerchantDistributes(app),
      ChangePassword(app),
      Merchants(app),
      MerchantPowers(app),
      MerchantClients(app),
      MerchantMiners(app),
      MemberInitialPledges(app),
      MemberProducts(app),
      Page403(),
      Page500(),
      NotFound()
    ]
  }
];

export default app => createRoutes(app, routesConfig);
